import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllCampaignsQuery, useDeleteCampaignsMutation, useCreateCampaignsMutation, useUpdateCampaignsMutation } from '../../services/campaigns';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import SearchIcon from "@material-ui/icons/Search";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';
import { Tag } from 'antd';

import { Menu, Dropdown } from 'antd';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

var moment = require('moment');
function Campaigns() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const { data: listing, refetch, isLoading, isFetching } = useGetAllCampaignsQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = listing?.campaigns;
  const pagination = listing?.pagination;

  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     // refetch()
  //   }, 900000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);



  const [deleteList, deleteresult] = useDeleteCampaignsMutation()
  const [createNew, createresult] = useCreateCampaignsMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getSubject, setSubject] = useState("")
  const [getFromName, setFromName] = useState("")
  const [getFromEmail, setFromEmail] = useState("")
  const [getReplyToEmail, setReplyToEmail] = useState("")

  const [getId, setId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setSubject(data.subject)
    setFromName(data.from_name)
    setFromEmail(data.from_email)
    setReplyToEmail(data.reply_toemail)

    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    subject: getSubject,
    from_name: getFromName,
    from_email: getFromEmail,
    reply_toemail: getReplyToEmail,
    userId: userId,
    userToken: userToken,
    admin: admin
  }
  const [updatePost, updateresult] = useUpdateCampaignsMutation()



  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }


  const [checked, setChecked] = React.useState([]);

  const handleChange = (data) => {
    setChecked(data);
  };


  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
      setChecked([])
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }

    if (deleteresult?.isSuccess) {
      setChecked([])
    }
  }, [updateresult, createresult, deleteresult]);


  // function viewOpens(data) {
  //   const countOpens = [...new Set(data?.split(',')?.map((item) => item))]
  //   return countOpens?.length;
  // }
  // function viewClicked(data) {
  //   const countClicks = [...new Set(data?.split(',')?.map((item) => item))]
  //   return countClicks?.length;
  // }
  // function viewUnsubscribed(data) {
  //   const countUnsubscribe = [...new Set(data?.split(',')?.map((item) => item))]
  //   return countUnsubscribe?.length;
  // }


 

  function permanentDelDrop() {
    if (checked?.event) {
      if (checked?.send_status) {
        return true
      } else if (checked?.scheduled) {
        return true
      } else if (checked?.starts) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }


  function reportDrop() {
    if (checked?.event) {
      if (checked?.send_status) {
        return false
      } else if (checked?.scheduled) {
        return true
      } else if (checked?.starts) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }






  return (
    <div className="Order-style">
      <Modal className="new_Modal" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(createNew)}>
            <div className="row">
              <input type="hidden" id="userId" defaultValue={userId} aria-invalid={errors.userId ? "true" : "false"}
                {...register('userId', { required: false })} />
              <input type="hidden" id="userToken" defaultValue={userToken} aria-invalid={errors.userToken ? "true" : "false"}
                {...register('userToken', { required: false })} />
              <input type="hidden" id="admin" defaultValue={admin} aria-invalid={errors.admin ? "true" : "false"}
                {...register('admin', { required: false })} />
              <div className="col-md-12">
                <div className="form-group">
                  <label>Campaign Name</label>
                  <input type="text" className="form-control dlrdflInput" id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Email Subject</label>
                  <input type="text" className="form-control dlrdflInput" id="subject"
                    aria-invalid={errors.subject ? "true" : "false"}
                    {...register('subject', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>From Email</label>
                  <input type="email" className="form-control dlrdflInput" id="from_email"
                    aria-invalid={errors.from_email ? "true" : "false"}
                    {...register('from_email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>From Name</label>
                  <input type="text" className="form-control dlrdflInput" id="from_name"
                    aria-invalid={errors.from_name ? "true" : "false"}
                    {...register('from_name', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Reply to email?</label>
                  <input type="email" className="form-control dlrdflInput" id="reply_toemail"
                    aria-invalid={errors.reply_toemail ? "true" : "false"}
                    {...register('reply_toemail', { required: true })} />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <Button
                    color="info"
                    variant="contained"
                    size="medium"
                    disabled={createresult?.isLoading ? true : false}
                    type="submit"
                    style={{ width: '100%', height: '45px' }}
                  >
                    {createresult?.isLoading ? 'loading...' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Compaign Name</label>
                <input type="text" className="form-control dlrdflInput" value={getName}
                  onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Subject</label>
                <input type="text" className="form-control dlrdflInput" value={getSubject}
                  onChange={(e) => setSubject(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>From Email</label>
                <input type="email" className="form-control dlrdflInput" value={getFromEmail}
                  onChange={(e) => setFromEmail(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>From Name</label>
                <input type="text" className="form-control dlrdflInput" value={getFromName}
                  onChange={(e) => setFromName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Reply to email?</label>
                <input type="email" className="form-control dlrdflInput" value={getReplyToEmail}
                  onChange={(e) => setReplyToEmail(e.target.value)} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <Button
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: '100%', height: '45px' }}
                  disabled={updateresult?.isLoading ? true : false}
                  onClick={() => { updatePost(updateData) }}>
                  {updateresult?.isLoading ? 'loading...' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="Order-Top_navbar">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="table_search_card">
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                    <SearchIcon className="table_search_icon" />
                  </div>

                  {(isFetching) && (!isLoading) &&
                    <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>

                <div className="dFlex center nowrap">


                  <Dropdown
                    className="Header-waller-connect2 mr10"
                    overlay={(
                      <Menu className="Header-dropdown-menu">
                        <Menu.Item
                          key="0"
                          disabled={checked?.event ? false : true}
                        >
                          <Link to={`/campaign/${checked?.id}?name=${checked?.name}`} >
                            View Details
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="1"
                          disabled={checked?.event ? false : true}
                          onClick={() => deleteList({
                            id: checked?.id,
                            userId: userId,
                            userToken: userToken,
                            admin: admin,
                            duplicate: 'duplicate'
                          })}
                        >
                          Duplicate Campaign
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          disabled={reportDrop()}
                        >
                          <Link to={`/report/${checked?.id}`} >
                            Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key="3"
                          disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.permanent_delete === 'permanent_delete' ? true : permanentDelDrop()}
                          onClick={() => {
                            if (window.confirm('Are you sure to permanent delete this user?')) deleteList({
                              id: checked?.id,
                              userId: userId,
                              userToken: userToken,
                              admin: admin,
                              permanent_delete: 'permanent_delete'

                            })
                          }}
                        >
                          Permanent Delete
                        </Menu.Item>
                      </Menu>
                    )} trigger={['click']}>
                    <div onClick={e => e.preventDefault()} style={{ display: 'flex', alignItems: 'center', border: '1px solid rgba(0, 0, 0, 0.12)', padding: '5px 10px 5px 10px', borderRadius: '4px', justifyContent: 'space-between', width: 'auto', cursor: 'pointer' }}>
                      <div style={{ color: '#7b8087' }}>Action</div>
                      <IconButton aria-haspopup="true" style={{ padding: '0' }}>
                        <KeyboardArrowDownIcon style={{ color: '#1e2f438f' }} />
                      </IconButton>
                    </div>
                  </Dropdown>

 
                  <Button
                    disabled={permanentDelDrop()}
                    variant="outlined"
                    size="medium"
                    className="mr10"
                    onClick={() => handleOpenEdit({
                      status: true,
                      name: checked?.name,
                      subject: checked?.subject,
                      from_name: checked?.from_name,
                      from_email: checked?.from_email,
                      reply_toemail: checked?.reply_toemail,
                      id: checked?.id
                    })}
                  >
                    Edit
                  </Button>
                  <Button
                    color="warning"
                    variant="contained"
                    size="medium"
                    onClick={() => handleOpen(true)}>
                    Add New
                  </Button>

                </div>
              </div>
              <div className="Order-Table_view">
                <div className="Order-Table_card">
                  <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                    <div className="Order-Table_item heading fw700" style={{ width: '0px' }}></div>
                    <div className="Order-Table_item heading fw700">Name</div>
                    <div className="Order-Table_item heading fw700">To Send</div>
                    <div className="Order-Table_item heading fw700">To Contacts</div>
                    <div className="Order-Table_item heading fw700">Schedule Date</div>
                    <div className="Order-Table_item heading fw700">Send Date</div>
                    <div className="Order-Table_item heading fw700 sticky-column tar">Status</div>
                  </div>
                  {isLoading &&
                    <Skeletons />
                  }
                  {suppliers?.map((item, i) =>
                    <>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item text" style={{ width: '0px' }}>
                          <Checkbox
                            // disabled={(item.scheduled) || (item.send_status) ? true : false}
                            size="small"
                            checked={checked?.id === item.id ? checked?.event : false}
                            onChange={((event) => handleChange({
                              event: event.target.checked,
                              name: item.name,
                              subject: item.subject,
                              from_name: item.from_name,
                              from_email: item.from_email,
                              reply_toemail: item.reply_toemail,
                              id: item.id,
                              status: item.status,
                              scheduled: item.scheduled,
                              send_status: item.send_status,
                              starts: item.starts
                            }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </div>
                        <div className="Order-Table_item text">
                          <Link to={`/campaign/${item.id}?name=${item.name}`} >
                            {item.name}
                          </Link>
                        </div>
                        <div className="Order-Table_item text">
                          <div>{item.to_send}</div>
                        </div>
                        <div className="Order-Table_item text">
                          <div>{item.to_contacts}</div>
                        </div>
                        <div className="Order-Table_item text">{item.schedule_date && moment.utc(item.schedule_date).format("MMM Do YYYY hh:mm")}</div>
                        <div className="Order-Table_item text">{item.send_date && moment.utc(item.send_date).format("MMM Do YYYY")}</div>
                        <div className="Order-Table_item text sticky-column">
                          <div className="dFlex center nowrap jcr">
                            <div style={{ width: '100%' }}>
                              {!item.status &&
                                <Tag color={"#eb8188"} style={{ color: '#fcfcfc', width: '100%', height: '28px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  {'Deleted'}
                                </Tag>
                              }
                            </div>
                          </div>
                          <div>
                            {item.status ?
                              <LinearProgress
                                determinate
                                variant="outlined"
                                color="neutral"
                                size="sm"
                                thickness={32}
                                value={item.to_send ? ((parseInt(item.to_contacts) / parseInt(item.to_send)) * 100).toFixed(2) : 0}
                                sx={{
                                  '--LinearProgress-radius': '0px',
                                  '--LinearProgress-progressThickness': '24px',
                                  boxShadow: 'sm',
                                }}
                              >
                                <Typography
                                  level="body3"
                                  fontWeight="xl"
                                  textColor="#8f8c8c"
                                  style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px' }}
                                  sx={{ mixBlendMode: 'difference' }}
                                >
                                  {item.starts && !item.send_status ? <>SENDING…</> : ''}
                                  {!item.starts && item.send_status ? 'SENT ' : ''}

                                  {!item.starts && !item.send_status ? 'PENDING ' : ''}

                                  {item.starts || item.send_status ? <>
                                    {item.to_send ? ((parseInt(item.to_contacts) / parseInt(item.to_send)) * 100).toFixed(2) + '%' : 0}</> : ''}

                                  {item.starts && !item.send_status ? <>&nbsp;&nbsp;<Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px' }} animation="border" /></> : ''}
                                </Typography>
                              </LinearProgress> : ''
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {suppliers?.length === 0 &&
                  <h1 className="Order-no_data_found">Not Data Found!</h1>
                }
              </div>
              <div className="Order-Pagination">
                <Pagination
                  style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                  page={parseInt(page)}
                  count={parseInt(pagination?.totalPages)}
                  color="primary"
                  variant="outlined"
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                      {...item}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
