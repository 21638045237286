import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllCountsQuery } from '../../services/index';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { Tag } from 'antd';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
var moment = require('moment');
function Dashboard() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const countsData = useGetAllCountsQuery({ userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const activeSubscribers = countsData?.data?.active;
  const unsubscribedSubscribers = countsData?.data?.unsubscribed;
  const bouncedSubscribers = countsData?.data?.bounced;
  const totalSubscribers = countsData?.data?.total;

  const [progress, setProgress] = React.useState(0);

  return (
    <div className="Order-style">
      <div className="dashboardStyle">
        <div className="card card-dashboard-seven" style={{ padding: '20px', backgroundColor: '#f7f7f7', borderRadius: 0 }}>
          <div className="container">
            <div className="db_welcome-msg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <Button
                  onClick={() => history.push(`/campaigns`)}
                  color={`info`}
                  variant="outlined"
                  size="small"
                  style={{ marginBottom: '10px' }}
                >
                  <small className="dFlex">{`Campaigns`}</small>
                </Button>
                <h2>{'Activities'}</h2>
              </div>

            </div>
            <br /><br />
            <div className="card-body-db">
              <div className="row">
                <div className="col-6 col-lg-3 seven-content-db">
                  <label>Total Active Subscribers</label>
                  <div>
                    {countsData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                      <>
                        <Tag color={"#72c585"} style={{ color: '#fcfcfc' }}>
                          <h4 style={{ color: '#fcfcfc' }}>{activeSubscribers && activeSubscribers}</h4>
                        </Tag>
                      </>
                    }
                  </div>
                  <span><strong>{totalSubscribers ? ((parseInt(activeSubscribers) / parseInt(totalSubscribers)) * 100).toFixed(2) + '%' : ''}</strong></span>
                </div>
                <div className="col-6 col-lg-3 seven-content-db">
                  <label>Total Unsubscribed</label>
                  <div>
                    {countsData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                      <>
                        <Tag color={"#eb8188"} style={{ color: '#fcfcfc' }}>
                          <h4 style={{ color: '#fcfcfc' }}>{unsubscribedSubscribers && unsubscribedSubscribers}</h4>
                        </Tag>
                      </>
                    }
                  </div>
                  <span><strong>{totalSubscribers ? ((parseInt(unsubscribedSubscribers) / parseInt(totalSubscribers)) * 100).toFixed(2) + '%' : ''}</strong></span>
                </div>
                <div className="col-6 col-lg-3 seven-content-db">
                  <label>Total Bounced </label>
                  <div>
                    {countsData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                      <>
                        <Tag color={"#fed374"} style={{ color: '#fcfcfc' }}>
                          <h4>{bouncedSubscribers && bouncedSubscribers}</h4>
                        </Tag>
                      </>
                    }
                  </div>
                  <span><strong>{totalSubscribers ? ((parseInt(bouncedSubscribers) / parseInt(totalSubscribers)) * 100).toFixed(2) + '%' : ''}</strong></span>
                </div>
              </div>
            </div>
            <br /><br />
            {/* <div className="db_welcome-msg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <h2>{'Information'}</h2>
              </div> 
            </div> */}

          </div>
        </div>
      </div>
      <br /><br />
    </div>
  );
}

export default Dashboard;
